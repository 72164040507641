export class Post {
  public id: string;
  public text: string;
  public date: Date;
  public publisherUsername: string;
  public upvotes: number;
  public downvotes: number;
  public mediaBase64: string = "";

  constructor(
    id: string,
    text: string,
    date: Date,
    publisherUsername: string,
    upvotes: number,
    downvotes: number,
    mediaBase64?: string
  ) {
    this.id = id;
    this.text = text;
    this.date = date;
    this.publisherUsername = publisherUsername;
    this.upvotes = upvotes;
    this.downvotes = downvotes;
    if (mediaBase64) this.mediaBase64 = mediaBase64;
  }

  public static deepCopy = (source: Post): Post => {
    let temp = Object.create(source);
    Object.assign(temp, source);
    return temp;
  };
}

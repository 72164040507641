import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions } from '@mui/material';
import React, { useState } from 'react';
import { Post } from '../../classes/Post';
import { User } from '../../classes/User';
import axios from 'axios';
import { handleError } from '../../utils/errorHandler';
import { blobToBase64 } from '../../utils/extras';

interface Props {
    open: boolean;
    user: User;
    onClose: (post?: Post) => void;
}

const AddPostDialogue = (props: Props) => {

    const [text, setText] = useState("");
    const [imageUrl, setImageUrl] = useState('');

    const handleClose = async (isSuccess: boolean) => {
        if (isSuccess) {
            let imageBase64Url = '';
            try {
                const res = await axios.get(imageUrl, { responseType: 'blob' });
                const imgBlob: any = res.data;
                imageBase64Url = await blobToBase64(imgBlob);
            } catch (error) {
                handleError(error);
            }
            props.onClose(new Post('', text, new Date(), props.user.username, 0, 0, imageBase64Url));
        } else {
            props.onClose();
        }

        setText("");
        setImageUrl("");
    }

    return (
        <Dialog onClose={() => handleClose(false)} aria-labelledby="simple-dialog-title" open={props.open}>
            <DialogTitle id="simple-dialog-title">Make a Post</DialogTitle>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleClose(true);
            }}>
                <DialogContent>
                    <TextField label="Text" variant="outlined" value={text}
                        style={{ marginBottom: '15px' }} type="text" onChange={(input) => {
                            setText(input.target.value);
                        }} required fullWidth minRows={4} multiline />
                    <div style={{
                        width: '100%',
                        minWidth: '400px',
                        borderRadius: '3px',
                        boxShadow: '0 0 2px black',
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        marginBottom: '18px',
                        padding: '10px'
                    }}>
                        <div className="AskQuestionImage_container" style={{
                            width: 'inherit',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '3px',
                            boxShadow: '0 0 2px black',
                        }}>
                            <img src={imageUrl} alt="" style={{ width: '100%' }} />
                        </div>
                        <Button
                            variant="outlined"
                            component="label"
                            color="primary"
                            style={{ marginTop: '10px' }}
                        >
                            Upload Image
                            <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                hidden
                                onChange={(input) => {
                                    if (input.target.files && input.target.files.length > 0) {
                                        setImageUrl(URL.createObjectURL(input.target.files[0]));
                                    }
                                }}
                            />
                        </Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Post
                    </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default AddPostDialogue;
import { Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import { useEffect, useState } from 'react';
import { Post } from '../../classes/Post';
import axiosInstance from '../../utils/axiosInstance';
import { handleError } from '../../utils/errorHandler';
import './postComponent.css'
import axios from 'axios';
import { getTimeDiff } from '../../utils/extras';

interface Props {
    username: string;
    postId: string;
}

const PostComponent = (props: Props) => {
    const [post, setPost] = useState(new Post('', '', new Date(), '', 0, 0));
    const [postImgUrl, setPostImgUrl] = useState('');

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const res = await axiosInstance.get(`/api/posts/${props.postId}`);
                const resultData: any = res.data;
                setPost(new Post(resultData.postId, resultData.text, new Date(resultData.date), resultData.publisherUsername,
                    resultData.upvotes, resultData.downvotes, resultData.mediaBase64));
                setPostImgUrl(await getPostImage(resultData.mediaBase64));
            } catch (error) {
                handleError(error);
            }
        }
        fetchPost();
    }, []);

    const getPostImage = async (mediaBase64: string): Promise<string> => {
        return new Promise(async (resolve, _) => {
            if (!mediaBase64 || mediaBase64.length <= 0) return resolve('');
            try {
                const res = await axios.get(mediaBase64, { responseType: 'blob' });
                resolve(URL.createObjectURL(res.data));
            } catch (error) {
                handleError(error);
                resolve('');
            }
        });
    }

    const handleUpvote = async () => {
        try {
            if (post.publisherUsername === props.username) throw new Error('You cannot vote your own post')
            await axiosInstance.patch(`/api/posts/${props.postId}/upvote`);
            post.upvotes++;
            setPost(Post.deepCopy(post));
        } catch (error) {
            handleError(error);
        }
    }

    const handleDownvote = async () => {
        try {
            if (post.publisherUsername === props.username) throw new Error('You cannot vote your own post')
            await axiosInstance.patch(`/api/posts/${props.postId}/downvote`);
            post.downvotes++;
            setPost(Post.deepCopy(post));
        } catch (error) {
            handleError(error);
        }
    }

    const displayVotes = (votes: number): string => {
        return String(votes);
    }

    const getPostView = (): JSX.Element => {
        if (post.id.length <= 0) return <React.Fragment />;
        return (
            <div className="post_container">
                <div className="postCard_container">
                    <div className="post_title_container">
                        <h3>@{post.publisherUsername} &#183;</h3>
                        <h4>{getTimeDiff(post.date)} ago</h4>
                    </div>
                    <div className="post_text_container">
                        <h5>{post.text}</h5>
                    </div>
                    {postImgUrl.length > 0 && (
                        <div className="post_media_container">
                            <img src={postImgUrl} alt="" />
                        </div>
                    )}
                    <div className="post_info_container">
                        <Button variant="text" size="small" startIcon={<KeyboardArrowUpIcon />} onClick={handleUpvote}>
                            {displayVotes(post.upvotes)}
                        </Button>
                        <Button variant="text" size="small" startIcon={<KeyboardArrowDownIcon />} color="error"
                            onClick={handleDownvote}>
                            {displayVotes(post.downvotes)}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return getPostView();
}

export default PostComponent;
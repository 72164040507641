import { Post } from "./Post";

export class User {
  public username: string;
  public name: string;
  public publishedPosts: Post[];
  public feed: Post[];
  public creationDate: Date;
  public static fake_username: string = "$$##%%";

  constructor(
    username: string,
    name: string,
    publishedPosts: Post[],
    feed: Post[],
    creationDate: Date
  ) {
    this.username = username;
    this.name = name;
    this.publishedPosts = publishedPosts;
    this.feed = feed;
    this.creationDate = creationDate;
  }

  public static deepCopy = (source: User): User => {
    let temp = Object.create(source);
    Object.assign(temp, source);
    return temp;
  };
  public isLoading = (): boolean => {
    return this.username === "";
  };
  public isLoggedIn = (): boolean => {
    return this.username !== "" && this.username !== User.fake_username;
  };
  public getExpityTime = (): Date => {
    let time = new Date(this.creationDate);
    time.setDate(time.getDate() + 1);
    return time;
  };
}

import './App.css';
import { useEffect, useState } from 'react';
import axiosInstance from './utils/axiosInstance';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import HomeComponent from './components/homePage/HomeComponent';
import SignUpComponent from './components/signupPage/SignUpPageComponent';
import { User } from './classes/User';
import { handleError } from './utils/errorHandler';

const App = () => {

  const [user, setUser] = useState(new User('', '', [], [], new Date()));

  useEffect(() => {
    const autoLoginUser = async () => {
      try {
        const result = await axiosInstance.get(`/api/login`);
        const resultData: any = result.data;
        setUser(new User(resultData.username, resultData.name, [], [], new Date(resultData.creationDate)));
      } catch (error) {
        user.username = User.fake_username;
        setUser(User.deepCopy(user));
        handleError(error);
      }
    }
    autoLoginUser()
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path={`/`} exact render={(tempProps) => {
            if (user.isLoggedIn())
              return <HomeComponent {...tempProps} user={user} setUser={setUser} />
            else if (user.isLoading())
              return <div></div>
            else
              return <Redirect to={`/signup`} />
          }} />
          <Route path={`/signup`} exact render={(tempProps) => {
            if (user.isLoggedIn())
              return <Redirect to={`/`} />
            else if (user.isLoading())
              return <div></div>
            else
              return <SignUpComponent {...tempProps} setUser={setUser} />
          }} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import './HomeComponent.css';
import { Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { User } from '../../classes/User';
import axiosInstance from '../../utils/axiosInstance';
import { handleError } from '../../utils/errorHandler';
import PostComponent from '../postComponent/postComponent';
import AddPostDialogueComponent from '../dialogComponents/addPostDialogueComponent';
import { Post } from '../../classes/Post';
import { getTimeDiff } from '../../utils/extras';

interface Props {
    user: User;
    setUser: (user: User) => void;
}

const HomeComponent = (props: Props) => {
    const [userFeed, setUserFeed] = useState<string[]>([]);
    const [isAddPostDialogueOpen, setAddPostDialogueOpen] = useState(false);
    useEffect(() => {
        // fetch user feed here
        const getUserFeed = async () => {
            try {
                const res = await axiosInstance.get(`/api/posts`);
                const resultData = res.data;
                //@ts-ignore
                setUserFeed(resultData);
            } catch (error) {
                handleError(error);
            }
        }
        getUserFeed();
    }, []);
    const logoutHandler = () => {
        props.user.username = User.fake_username;
        props.setUser(User.deepCopy(props.user));
    }
    const handleAddPostDialogueOpen = () => {
        setAddPostDialogueOpen(true);
    }
    const handleSendPost = async (post?: Post) => {
        setAddPostDialogueOpen(false);
        if (!post) return; // post is undefined 
        try {
            const res = await axiosInstance.post(`/api/posts`, post);
            setUserFeed([String(res.data), ...userFeed]);
        } catch (error) {
            // post upload failed
            handleError(error);
        }
    }
    return (
        <div className="homePage_container">
            <div className="userFeed_container">
                <Typography variant='h5' style={{ marginBottom: '10px' }}>Home</Typography>
                <Button variant="contained" size="small" style={{ alignSelf: 'flex-end' }}
                    onClick={handleAddPostDialogueOpen}>Add Post</Button>
                <AddPostDialogueComponent open={isAddPostDialogueOpen} user={props.user} onClose={handleSendPost} />
                {userFeed.map((postId: string) => {
                    return <PostComponent key={postId} postId={postId} username={props.user.username} />;
                })}
            </div>
            <div className="userInfo_container">
                <Typography variant="h6">{props.user.name}</Typography>
                <Typography variant="subtitle1" style={{ fontSize: '15px', marginBottom: '5px' }}>@{props.user.username}</Typography>
                <Typography variant="body2" style={{ marginBottom: '6px' }}>Your Flash Account will expire in
                    <strong> {getTimeDiff(props.user.getExpityTime())}</strong>.
                </Typography>
                <Button variant="outlined" size='small' onClick={logoutHandler}>Logout</Button>
            </div>
        </div>
    );
}

export default HomeComponent;

import { useState } from 'react';
import './SignUpPageComponent.css';
import axiosInstance from '../../utils/axiosInstance';
import { handleError } from '../../utils/errorHandler';
import { Button, TextField, Typography, Alert } from '@mui/material';
import { User } from '../../classes/User';

interface Props {
    setUser: (user: User) => void;
}

const SignUpPageComponent = (props: Props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const signUpHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (username.length <= 0) {
            setErrorMessage('Username is empty');
            return;
        }
        if (name.length <= 0) {
            setErrorMessage('Display name is empty');
            return;
        }
        try {
            const data = {
                username: username,
                name: name,
            }
            const res = await axiosInstance.post(`/api/signup`, data);
            const resultData: any = res.data;
            props.setUser(new User(resultData.username, resultData.name, [], [], new Date(resultData.creationDate)));
        } catch (error) {
            handleError(error);
        }
    }
    return (
        <div className="signUpPage_Container">
            <form onSubmit={signUpHandler}>
                <Typography variant="h4" style={{ marginBottom: "15px" }}>Sign Up</Typography>
                <TextField error={errorMessage.length > 0} label="Username" variant="outlined" type="text"
                    style={{ marginBottom: "15px" }}
                    onChange={(input) => {
                        const value: string = String(input.target.value);
                        setUsername(value.trim());
                        setErrorMessage('');
                    }} />
                <TextField error={errorMessage.length > 0} label="Display name" variant="outlined" type="text"
                    style={{ marginBottom: "15px" }}
                    onChange={(input) => {
                        const value: string = String(input.target.value);
                        setName(value.trim());
                        setErrorMessage('');
                    }} />
                <Button variant="contained" type="submit">Sign Up</Button>
                {
                    errorMessage.length > 0 &&
                    <Alert severity="error" style={{ marginTop: "15px" }}>{errorMessage}</Alert>
                }
            </form>
        </div>
    );
}

export default SignUpPageComponent;

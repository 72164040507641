export const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (!reader.result) reject("error in base64 conversion");
      else resolve(String(reader.result));
    };
    reader.readAsDataURL(blob);
  });
};

export const getTimeDiff = (time: Date): string => {
  let diff: number = Math.abs(new Date().getTime() - time.getTime());
  let minutes = diff / 60000;
  let hours = minutes / 60;
  let days = hours / 24;
  if (days >= 1) return `${Math.trunc(days)} days`;
  if (hours >= 1) return `${Math.trunc(hours)} hrs`;
  if (minutes >= 1) return `${Math.trunc(minutes)} mins`;
  return `1 mins`;
};
